* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

nav > ul > li > .navLink {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

/* navbar */
.navbar {
  top: 0;
  background: black;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* gap: 40px; */
  min-height: 70px;
}

/* ul  */
.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 40px; */
  /* z-index: 1; */
}

.hamburger {
  display: none;
}

/* media queries */

@media screen and (min-width: 769px) and (max-width: 1279px) {
  .navbar > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    min-height: 70px;
  }
}

@media screen and (max-width: 780px) {
  .navbar {
    padding: 0px 1.5rem;
    background: radial-gradient(
      58.94% 138.79% at 32.53% 35.49%,
      #0b0b0b 0%,
      rgba(11, 11, 11, 0.48) 100%
    ),
    url(../images_new/Pexels\ Photo\ by\ Abuti\ Engidashet.png);

  }
  /* hamburger */
  .hamburger {
    position: relative;
    display: block;
  }

  nav > ul > li > .navLink {
    color: black;
  }

  .bar {
    /* position: relative; */
    background-color: #F8931F;
    width: 20px;
    height: 4px;
    display: block;
    margin: 12px 0px;
    /* -webkit-transition: 0.3s ease-in-out; */
    /* transition: 0.3s ease-in-out; */
  }

  .bar:nth-child(2) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    /* bottom: 5px; */
    width: 15px;
    margin: 0px;
  }

  /* adding active class to the bars  */
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(18px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(2px) rotate(-45deg);
  }

  /* navMenu */

  .navMenu {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    top: -120%;
    left: 0;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
    background-color: white;
    padding: 10px;

    height: 100vh;
  }

  .navMenu.active {
    top: 0;
  }

  .navMenu li {
    margin: 16px 0;
  }
}
@media screen and (min-width: 1367px) {
  nav {
    padding: 0px 150px !important;
    /* font-size: px; */
  }

  
}
