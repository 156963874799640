/* header1 & welcome section */
.mobile {
  display: none;
}

.welcome {
  padding-top: 18px;
}

/* header2 section */
.header2 {
  background: radial-gradient(58.94% 138.79% at 32.53% 35.49%, #0B0B0B 0%, rgba(11, 11, 11, 0.48) 100%), url(../images_new/Pexels\ Photo\ by\ Abuti\ Engidashet.png) ;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
}
/* footer section */
#contact {
  z-index: 1;
}

@import url("https://fonts.cdnfonts.com/css/leckerli-one");

.logo_text {
  font-family: "Leckerli One", sans-serif;
}

@media screen and (min-width: 1367px) {
  #messages{
    padding: 0px;
  }
  #message{
    padding: 0px;
  }
  section {
    width: 100%;
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .welcome{
    padding: 0px;
  }
  /* pict_text section */
  #mission_img,
  #vision_img {
    width: 50%;
  }
  #mission_img > img,
  #vision_img > img {
    width: 100%;
  }
  #mission_text,
  #vision_text {
    width: 50%;
  }

  /* messages section */
  #messages {
    gap: 28px;
  }

  /* message component */
  #message > div > .message {
    width: 100%;
  }
  #message > div > .message > img {
    width: 100%;
  }
  .desktop {
    width: 100%;
  }
 
  #explore>div:first-child{
    padding: 0px 20px;
  }

  /* welcome section */
  #welcome_img > img {
    width: 100%;
  }
  #welcome_text {
    width: 100%;
  }
  #welcome_txt {
    width: 60%;
  }
  /* join section */
  #testimonial>div:nth-child(2) {
    padding: 0px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  h1 {
    font-size: 28px !important;
  }
  .header2{
    z-index: -1;
  }
  /* header1 & welcome section */
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
/* 
  section > #connect_img {
    width: 12% !important;
  } */
  /* join section */

  .joinSection > div > div > a {
    margin: 20px auto 0px;
  }

  /* connect section mobile */

  .connect_img > img {
    width: 12% !important;
  }

  /* footer section mobile */
  .subscribe {
    width: 90%;
  }

  ul > li > a,
  section > div > p,
  footer > div > p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #contacts,
  #border-control {
    display: none;
  }
  /* #contact {
    display: ;
  } */
}
